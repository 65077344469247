<template>
  <section class="bando-head">
    <h1>Mon compte</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Mes informations</h2>

      <!-- INFOS -->
      <form @submit.prevent="create">
        <div class="bloc-infos bloc-shadow">
          <div class="form-group row">
            <label for="nom" class="col col-12 col-md-4">Nom</label>
            <div class="col col-6 col-md-3">
              <input
                type="text"
                class="form-control"
                name="prenom"
                placeholder="Prénom"
                v-model="moi.prenom"
              />
            </div>
            <div class="col col-6 col-md-3">
              <input
                type="text"
                class="form-control"
                name="nom"
                placeholder="Nom"
                v-model="moi.nom"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col col-12 col-md-4">Email</label>
            <div class="col col-12 col-md-6">
              <input
                type="email"
                class="form-control"
                name="email"
                placeholder="Email"
                v-model="moi.email"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="password" class="col col-12 col-md-4">
              Mot de passe
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="password"
                class="form-control"
                name="password"
                placeholder="Mot de passe"
                v-model="moi.password"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="confirm-password" class="col col-12 col-md-4">
              Confirmation mot de passe
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="password"
                class="form-control"
                name="confirm-password"
                placeholder="Confirmez votre mot de passe"
                v-model="moi.confirm_password"
              />
            </div>
          </div>
          <!-- <div class="form-group row">
            <label for="etablissement" class="col col-12 col-md-4">
              Etablissement
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="etablissement"
                placeholder="Etablissement"
                v-model="etablissement"
              />
            </div>
          </div> -->
        </div>

        <!-- <p class="pl-5"><small>* Informations obligatoires</small></p> -->

        <!-- Buttons -->
        <div class="text-center mb-5 pb-5">
          <button type="submit" class="btn btn-primary mx-3">Valider</button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "MonCompte",
  components: {},
  data() {
    return {
      moi: {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        confirm_password: ""
      },
      error: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ])
  },
  created() {
    console.log(this.getProfile);
    const profile = this.getProfile;
    this.moi.nom = profile.nom;
    this.moi.prenom = profile.prenom;
    this.moi.email = profile.email;
  },
  methods: {
    create: function() {
      this.loading = true;
      this.error = false;

      this.$swal.fire("Vos informations ont été modifiées avec succès.");

      // const {
      //   nom,
      //   prenom,
      //   email,
      //   password,
      //   confirm_password,
      //   etablissement
      // } = this;

      // axios
      //   .post(process.env.VUE_APP_API + "/beneficiaire", {
      //     civilite,
      //     nom,
      //     prenom,
      //     telephone_mobile,
      //     telephone_fixe,
      //     email,
      //     date_naissance,
      //     commentaire,
      //     reference
      //   })
      //   .then(resp => {
      //     this.$router.push("/");
      //     console.log(resp);
      //   })
      //   .catch(() => {
      //     this.$swal.fire("Une erreur s'est produite.");
      //   });
    }
  }
};
</script>

<style scope lang="scss">
.content {
  .bloc-shadow {
    margin-bottom: 3rem;
  }

  .bloc-infos {
    padding: 2rem 3rem;
    position: relative;

    @media screen and (min-width: 992px) {
      padding: 2rem 4rem 2rem 1rem;
    }

    .col {
      padding: 0 1rem;
    }

    .form-group {
      align-items: center;

      label {
        padding-bottom: 0;
        padding-top: 0;

        @media screen and (min-width: 992px) {
          text-align: right;
        }
      }
    }

    .btn {
      height: 4.5rem;
      padding: 1rem 2rem;
    }
  }
}
</style>
